import React from "react"
import { BsLinkedin } from "react-icons/bs"
import { FaGithub } from "react-icons/fa"
import { AiFillBehanceSquare } from "react-icons/ai"
import { motion, MotionConfig } from "framer-motion"

const HeaderSocials = () => {
  return (
    <MotionConfig
      transition={{
        duration: 0.5,
        ease: "easeInOut"
      }}
    >
      <div className="header__socials">
        <motion.div whileHover={{ rotate: "360deg" }}>
          <a href="https://www.linkedin.com/in/presley-ilukhor-239144b4/" target="_blank">
            <BsLinkedin />
          </a>
        </motion.div>
        <motion.div whileHover={{ rotate: "360deg" }}>
          <a href="http://github.com/oshiolee" target="_blank">
            <FaGithub />
          </a>
        </motion.div>
        <motion.div whileHover={{ rotate: "360deg" }}>
          <a href="http://behance.net/presleyilukhor" target="_blank">
            <AiFillBehanceSquare />
          </a>
        </motion.div>
      </div>
    </MotionConfig>
  )
}

export default HeaderSocials
