import React from "react"
import "./portfolio.css"
import IMG1 from "../../assets/port0.png"
import IMG2 from "../../assets/port2.png"
import IMG3 from "../../assets/port1.png"
// 

const data = [
  {
    id: 1,
    image: IMG1,
    title: "Hair Saloon Business Website",
    github: "https://github.com/Oshiolee/Hair-Salon-Website.git",
    demo: "https://behance.net/gallery/161462765/Hair-Salon-Website"
  },
  {
    id: 2,
    image: IMG2,
    title: "Grocery E-commerce Site",
    github: "https://github.com/Oshiolee/Grocery-E-commerce-Website.git",
    demo: "https://behance.net/gallery/161778061/Grocery-E-commerce-Website"
  },
  {
    id: 3,
    image: IMG3,
    title: "Dark Theme Dashboard",
    github: "https://github.com/Oshiolee/Dark-Mode-Dashboard-App",
    demo: "https://behance.net/gallery/161465897/Dark-Theme-Dashboard"
  }
]
const Portfolio = () => {
  
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio_item-image"></div>
              <img src={image} alt="title" />
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target={"_blank"}>
                  Github
                </a>
                <a href={demo} className="btn btn-primary" target={"_blank"}>
                  Video Demo
                </a>
              </div>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Portfolio
