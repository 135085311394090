import React from "react"
import "./experience.css"
import { BsPatchCheckFill } from "react-icons/bs"
import { useRef } from "react"
import { useScroll, useTransform } from "framer-motion"

const Experience = () => {
  const ref = useRef()

  const { scrollYProgress } = useScroll({
    target: ref
  })

  const y = useTransform(scrollYProgress, [0, 1], [-300, 300])

  return (
    <section id="experience" ref={ref}>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container" style={{ y }}>
        <div className="experience_frontend">
          <h3>Frontend Developer</h3>
          <div className="experience__content">
            <article className="experience_details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Experience</small>
              </div>
            </article>

            <article className="experience_details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Tailwind CSS</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>

            <article className="experience_details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>

            <article className="experience_details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>Bootstrap</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>

            <article className="experience_details">
              <BsPatchCheckFill className="experience_details-icon" />
              <div>
                <h4>React</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Experience
