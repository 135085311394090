import React from "react"
import "./header.css"
import CTA from "./CTA"
import Pres from "../../assets/Pres.png"
import HeaderSocials from "./HeaderSocials"
import { motion } from "framer-motion"

const sliderVariants = {
  initial: {
    x: 0
  },
  animate: {
    x: "-220%",
    transition: {
      repeat: Infinity,
      repeatType: "mirror",
      duration: 20
    }
  }
}
const Header = () => {
  return (
    <section>
      <header>
        <div className="container header_container">
          <h5>Hello I Am</h5>
          <h1>Ilukhor Presley</h1>
          <h5 className="text-light">Frontend Developer</h5>
          <CTA />
          <HeaderSocials />

          <div className="pres">
            <img src={Pres} alt="pres" />
          </div>

          <a href="#contact" className="scroll_down">
            Scroll Down
          </a>
          <motion.div className="slidingText" variants={sliderVariants} initial="initial" animate="animate">
            Frontend Web Developer
          </motion.div>
        </div>
      </header>
    </section>
  )
}

export default Header
