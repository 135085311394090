import React from "react"
import "./nav.css"
import { FcHome } from "react-icons/fc"
import { FcReadingEbook } from "react-icons/fc"
import { FcWorkflow } from "react-icons/fc"
import { FcServices } from "react-icons/fc"
import { FcBriefcase } from "react-icons/fc"
import { FcBusinessContact } from "react-icons/fc"
import { useState } from "react"

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#")
  return (
    <nav>
      <a href="#" onClick={() => setActiveNav("#")} className={activeNav === "#" ? "active" : ""}>
        <FcHome />
      </a>
      <a href="#about" onClick={() => setActiveNav("#about")} className={activeNav === "#about" ? "active" : ""}>
        <FcReadingEbook />
      </a>
      <a href="#experience" onClick={() => setActiveNav("#experience")} className={activeNav === "#experience" ? "active" : ""}>
        <FcWorkflow />
      </a>
      <a href="#services" onClick={() => setActiveNav("#services")} className={activeNav === "#services" ? "active" : ""}>
        <FcServices />
      </a>
      <a href="#portfolio" onClick={() => setActiveNav("#portfolio")} className={activeNav === "#portfolio" ? "active" : ""}>
        <FcBriefcase />
      </a>
      <a href="#contact" onClick={() => setActiveNav("#contact")} className={activeNav === "#contact" ? "active" : ""}>
        <FcBusinessContact />
      </a>
    </nav>
  )
}

export default Nav
