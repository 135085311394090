import React from "react"
import "./contact.css"
import { MdOutlineEmail } from "react-icons/md"
import { BsWhatsapp } from "react-icons/bs"
import { useRef } from "react"
import emailjs from "emailjs-com"

const variants = {
  initial: {
    y: 500,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1
    }
  }
}
export const Contact = () => {
  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()

    emailjs.sendForm("service_pl22wt9", "template_gm7x6nb", form.current, "wDWP5sjWcX-sCdkHk")

    e.target.reset()
  }

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container" variants={variants} initial="initial" whileInView="animate">
        <div className="contact__options" variants={variants}>
          <article className="contact__option" variants={variants}>
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>ilukhorpresley@gmail.com</h5>
            <a href="mailto:ilukhorpresley@gmail.com" target={"_blank"}>
              Send a message
            </a>
          </article>

          <article className="contact__option" variants={variants}>
            <BsWhatsapp className="contact__option-icon" />
            <h4>Whatsapp</h4>
            <h5>+2348100511502</h5>
            <a href="https://api.whatsapp.com/send?phone+2348100511502" target={"_blank"}>
              Send a message
            </a>
          </article>
        </div>

        {/* END OF CONTACT OPTIONS */}
        <div className="formContainer">
          <form ref={form} onSubmit={sendEmail} initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 1, duration: 2 }}>
            <input type="text" name="name" placeholder="Your Full Name" required />
            <input type="email" name="email" placeholder="Your Email" required />
            <textarea name="message" row="8" placeholder="Your Message" required />
            <button type="submit" className="btn btn-primary">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default Contact
