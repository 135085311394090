import React from "react"
import "./footer.css"
import { FaFacebookF } from "react-icons/fa"
import { FiInstagram } from "react-icons/fi"
import { IoLogoTwitter } from "react-icons/io"

const Footer = () => {
  return (
    <section>
      <footer>
        <a href="#" className="footer__logo">
          Ilukhor Presley
        </a>
        <ul className="permalinks">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#experience">Experience</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#portfolio">Portfolio</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
        <div className="footer__socials">
          <a href="https://facebook.com/Oshiolee">
            <FaFacebookF />
          </a>
          <a href="https://instagram.com/oshiolee" className="">
            <FiInstagram />
          </a>
          <a href="https://twitter.com/oshioleeiluk" className="">
            <IoLogoTwitter />
          </a>
        </div>

        <div className="footer__copyright">
          <small>copyright &copy; 2024 - PRESLEY OSHIORIAMHE ILUKHOR</small>
        </div>
      </footer>
    </section>
  )
}

export default Footer
